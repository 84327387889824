/* eslint-disable react/prop-types */
// import { contentStyles } from 'components';
import { Section, textStyles } from 'components';
import EcospheresNav from 'components/EcospheresNav';
import { graphql } from 'gatsby';
import React from 'react';
import Layout from 'templates/Layout';
import tw, { css } from 'twin.macro';

export const contentStyles = css`
  ${tw`lg:max-w-4xl mx-auto`}
  ${textStyles}
`;

export const ecosystemPageQuery = graphql`
  query {
    allDatoCmsDanishStartupsInNumbersPage(sort: { fields: position }) {
      edges {
        node {
          slug
          title
        }
      }
    }
    datoCmsDanishStartupsInNumbersAboutPage {
      bodyTextNode {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;

function AboutPage({ data }) {
  const { allDatoCmsDanishStartupsInNumbersPage, datoCmsDanishStartupsInNumbersAboutPage } = data;
  const pages = allDatoCmsDanishStartupsInNumbersPage.edges.map((eco) => eco.node);
  const { bodyTextNode } = datoCmsDanishStartupsInNumbersAboutPage;

  return (
    <Layout
      seo={{
        title: 'About Danish Startups in Numbers',
        description:
          '"Danish Startups in numbers" showcases every startup in the 7 digital ecosystems of Fintech, Healthtech, Robotics, Createch, Proptech, Agritech and Edtech.',
      }}
    >
      <EcospheresNav
        pages={[
          {
            slug: 'all-startups',
            title: 'All',
          },
          ...pages,
          {
            slug: 'about',
            title: 'About',
          },
        ]}
      />
      <Section>
        <div
          css={[contentStyles]}
          dangerouslySetInnerHTML={{ __html: bodyTextNode.childMarkdownRemark.html }}
        />
      </Section>
    </Layout>
  );
}

export default AboutPage;
